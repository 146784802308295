import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PageContent = function (props) {
	const {children, title} = props;

	return (
		<StyledContent>
			{title && <StyledTitle>{title}</StyledTitle>}
			<main>{children}</main>
		</StyledContent>
	);
};

PageContent.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string
};

const StyledContent = styled.article`
	display: block;
	width: 100%;
	max-width: 990px;
	padding: 1rem;
	margin: 3rem auto;

	> main {
		margin: 0.6rem 0;
	}

	p {
		margin: 0.7rem 0;
		line-height: 1.6;
	}

	a {
		color: #fe6023;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
`;

const StyledTitle = styled.h1`
	display: block;
	font-size: 2rem;
	padding: 0.3rem 0;
	line-height: 1.5;
	font-weight: normal;
`;

export default PageContent;
