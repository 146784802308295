import React from 'react';
import Fade from 'react-reveal/Fade';
import {useStaticQuery, graphql} from 'gatsby';
// import { openModal, closeModal } from '@redq/reuse-modal';

import Text from '../../../common/components/Text';
import Video from '../../../common/components/Video';
import Button from '../../../common/components/Button';
import Heading from '../../../common/components/Heading';
import Container from '../../../common/components/UI/Container';
import BannerWrapper, {
	BannerContent,
	BannerImage,
	ButtonGroup
	// VideoWrapper,
} from './banner.style';

import appVideo from '../../../common/assets/image/appModern/Jumpin.webm';

// const randomGenerator = (min, max) => {
// 	return Math.floor(Math.random() * (max - min + 1)) + min;
// };
//
// close button for modal
// const CloseModalButton = () => (
//   <Button
//     className="modalCloseBtn"
//     variant="fab"
//     onClick={() => closeModal()}
//     icon={<i className="flaticon-plus-symbol" />}
//   />
// );
//
// const ModalContent = () => (
//   <VideoWrapper>
//     <iframe
//       title="Video"
//       src="https://www.youtube.com/embed/8ME-QAlW6Ww"
//       frameBorder="0"
//     />
//   </VideoWrapper>
// );

const Banner = () => {
	const data = useStaticQuery(graphql`
		query {
			appModernJson {
				background {
					id
					image {
						publicURL
					}
				}
			}
		}
	`);
	const {background: backgrounds} = data.appModernJson;
	// const background = backgrounds[randomGenerator(0, backgrounds.length - 1)]
	const background = backgrounds[3];

	// modal handler
	// const handleVideoModal = () => {
	//   openModal({
	//     config: {
	//       className: 'video-modal',
	//       disableDragging: true,
	//       default: {
	//         width: 'auto',
	//         height: 'auto',
	//         x: 0,
	//         y: 0,
	//       },
	//     },
	//     component: ModalContent,
	//     componentProps: {},
	//     closeComponent: CloseModalButton,
	//     closeOnClickOutside: true,
	//   });
	// };

	return (
		<BannerWrapper id="home" background={background}>
			<Container>
				<BannerContent>
					<Fade up delay={100}>
						<Heading as="h1" content="ACTIVATE your life" />
					</Fade>
					<Fade up delay={200}>
						<Text as="h2" content="Gamified, real-life experiences" />
					</Fade>
					<Fade up delay={300}>
						<ButtonGroup>
							<Button
								className="primary"
								title="Create a challenge"
								as="a"
								href="https://l.jump.in/chlgCR"
								target="_blank"
								rel="noopener noreferrer"
							/>
							<Button
								className="outline-light"
								title="Do a challenge"
								as="a"
								href="https://l.jump.in/chlgDO"
								target="_blank"
								rel="noopener noreferrer"
								style={{marginLeft: '24px'}}
							/>
						</ButtonGroup>
					</Fade>
				</BannerContent>
				<BannerImage>
					<Fade up delay={100}>
						<Video>
							<source src={appVideo} type="video/webm" />
						</Video>
					</Fade>
				</BannerImage>
			</Container>
		</BannerWrapper>
	);
};

export default Banner;
