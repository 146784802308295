import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {base, themed} from '../base';

const VideoWrapper = styled('video')(
	{
		display: 'block',
		maxWidth: '100%',
		height: 'auto',
		paddingRight: '80px'
	},
	base,
	themed('Video')
);

const Video = ({children, ...props}) => (
	<VideoWrapper muted autoPlay loop {...props}>
		{children}
	</VideoWrapper>
);

export default Video;

Video.propTypes = {
	children: PropTypes.node.isRequired
};

Video.defaultProps = {
	m: 0
};
