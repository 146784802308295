import React from 'react';
import {Modal} from '@redq/reuse-modal';
import Sticky from 'react-stickynode';

import {ResetCSS} from '../common/assets/css/style';
import Navbar from '../containers/AppModern/Navbar';
import Banner from '../containers/AppModern/Banner';
import Footer from '../containers/AppModern/Footer';
import GlobalStyle, {AppWrapper, ContentWrapper} from '../containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import SEO from '../components/seo';
import PageContent from '../components/content/PageContent';
import EveryPageWrapper from '../components/EveryPageWrapper';

export default function () {
	return (
		<EveryPageWrapper>
			<>
				<SEO title="Support" />
				<Modal />
				<ResetCSS />
				<GlobalStyle />

				<AppWrapper>
					<Sticky top={0} innerZ={9999} activeClass="sticky-active">
						<Navbar />
					</Sticky>
					<ContentWrapper>
						<Banner />
						<PageContent title="Support">
							<script type="text/javascript" src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js" />
							<style type="text/css" media="screen, projection">
								@import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
							</style>
							<iframe
								title="Feedback Form"
								className="freshwidget-embedded-form"
								id="freshwidget-embedded-form"
								src="https://jumpin.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&screenshot=No&captcha=yes"
								scrolling="no"
								height="500px"
								width="100%"
								frameBorder="0"
							/>
						</PageContent>
					</ContentWrapper>
					<Footer />
				</AppWrapper>
			</>
		</EveryPageWrapper>
	);
}
